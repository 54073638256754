<template>
  <div>
    <!-- fixed top header -->
    <div class="fixed-top-action">
      <el-row type="flex" align="middle">
        <el-col :span="12" class="pl20">
          <div class="status-text">Unsaved changes</div>
        </el-col>
        <el-col :span="12" class="text-right pr20">
          <el-button type="default" @click="confirmLeaveForm = true"
            >Болих</el-button
          >
          <el-button type="success" :disabled="loading" @click="save('warehouseForm')">Хадгалах</el-button>
        </el-col>
      </el-row>
    </div>
    <el-row v-if="!pageStatus.error" v-loading="loading">
      <el-col :span="16" :offset="4">
        <!-- Бүх хуудсанд ашиглагдах header section -->
        <custom-header :title="warehouseForm.warehouse_id !== '' ? 'Агуулахын мэдээлэл засах' : 'Шинэ агуулах үүсгэх'"/>
        <!-- form error template -->
        <div class="form-error-container mb20" v-if="errorArray.length != 0">
          <header>Уучлаарай {{errorArray.length}} зүйл алдаатай байна</header>
          <ul class="error-list">
            <div v-for="(error, index) in errorArray" :key="index">
              <li>{{error.value}}</li>
            </div>
          </ul>
        </div>
        <el-form label-position="top" :model="warehouseForm" ref="warehouseForm" :rules="rules">
          <div class="panel">
            <div class="panel-item">
              <el-form-item label="Монгол нэр" prop="name_mon">
                <el-input v-model="warehouseForm.name_mon" placeholder="Дэд агуулах"></el-input>
              </el-form-item>
              <el-form-item label="Англи нэр" prop="name_eng">
                <el-input v-model="warehouseForm.name_eng" placeholder="Sub warehouse"></el-input>
              </el-form-item>
              <el-form-item label="Хаяг" prop="address">
                <el-input v-model="warehouseForm.address" placeholder="ХУД 3-р хороо Гоёо"></el-input>
              </el-form-item>
              <el-form-item label="Location">
                <el-input v-model="warehouseForm.location" disabled></el-input>
              </el-form-item>
              <div class="" style="width: 100%; height: 400px; display:block">
                <gmap-map
                  :center="googleMap.center"
                  :zoom="12"
                  :options="googleMap.options"
                  :draggable="false"
                  style="width:100%;  height: 100%; margin-top: 5px;"
                >
                  <gmap-marker
                    :position="googleMap.location"
                    :clickable="true"
                    @position_changed="changeLocation($event)"
                    :draggable="true"/>
                </gmap-map>
              </div>
            </div>
          </div>
          <div class="border-top pt20">
            <el-row :gutter="20">
              <el-col :span="8">
                <el-button type="danger" @click="confirmDeleteDialog = true">Устгах</el-button>
              </el-col>
              <el-col :span="16" class="text-right">
                <el-button type="default" @click="confirmLeaveForm = true">Болих</el-button>
                <el-button type="success" :disabled="loading" @click="save('warehouseForm')">Хадгалах</el-button>
              </el-col>
            </el-row>
          </div>
        </el-form>
      </el-col>
    </el-row>
    <div v-else class="text-center mtb50 text-color-secondary">
      {{pageStatus.message}}
    </div>
    <!-- Confirm discard changes dialog -->
    <el-dialog
      title="Discard all unsaved changes"
      :visible.sync="confirmLeaveForm"
      width="50%"
    >
      <span
        >If you discard changes, you’ll delete any edits you made since you last
        saved.</span
      >
      <span slot="footer" class="dialog-footer">
        <el-button @click="confirmLeaveForm = false" class="mr10"
          >Continue editing</el-button
        >
        <router-link to="/warehouse">
          <el-button type="danger" @click="confirmLeaveForm = false"
            >Discard changes</el-button
          >
        </router-link>
      </span>
    </el-dialog>
    <!-- Delete confirmation -->
    <el-dialog
      title="Татан авалт устгах"
      :visible.sync="confirmDeleteDialog"
      width="50%"
    >
      <div>
        <strong>{{warehouseForm.name_mon}}</strong> агуулахыг усгахдаа итгэлтэй байна уу?
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="confirmDeleteDialog = false" class="mr10"
          >Болих</el-button
        >
        <el-button type="danger" @click="deleteWarehouse(warehouseForm.warehouse_id)">
          Устгах
        </el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import services from '../../../helpers/services'
import CustomHeader from '../../../components/common/customHeader'
export default {
  name: 'warehouseForm',
  components: {
    CustomHeader
  },
  data () {
    return {
      googleMap: {
        center: {
          lat: 47.9174,
          lng: 106.9137
        },
        location: {
          lat: 47.9174,
          lng: 106.9137
        },
        markerOptions: {
          size: { width: 100, height: 90, f: 'px', b: 'px' },
          scaledSize: { width: 45, height: 45, f: 'px', b: 'px' }
        },
        options: {
          scrollwheel: true,
          mapTypeControl: true,
          streetViewControl: false,
          minZoom: 4,
          maxZoom: 20,
          gestureHandling: 'greedy'
        }
      },
      pageStatus: {
        error: false,
        message: ''
      },
      errorArray: [],
      loading: false,
      confirmLeaveForm: false,
      confirmDeleteDialog: false,
      warehouseForm: {
        name_mon: '',
        name_eng: '',
        location: '47.9174, 106.9137',
        address: '',
        key: 'testKeyField',
        warehouse_id: ''
      },
      rules: {
        name_mon: [
          { required: true, message: 'Монгол нэрийг оруулна уу', trigger: 'blur' }
        ],
        name_eng: [
          { required: true, message: 'Англи нэрийг оруулна уу', trigger: 'blur' }
        ],
        address: [
          { required: true, message: 'Хаягийн мэдээлэл оруулна уу', trigger: 'blur' }
        ]
      }
    }
  },
  created () {
    if (this.$route.params.warehouse_id) {
      this.getWarehouse(this.$route.params.warehouse_id)
    }
  },
  methods: {
    changeLocation (event) {
      this.warehouseForm.location = event.lat() + ', ' + event.lng()
    },
    getWarehouse (id) {
      services.getOneWarehouse(id).then(response => {
        if (response.status === 'success') {
          this.warehouseForm = response.data
          const location = this.warehouseForm.location.split(',')
          this.googleMap.location.lat = parseFloat(location[0])
          this.googleMap.location.lng = parseFloat(location[1])
          this.loading = false
        } else if (response.status === 'error') {
          this.pageStatus.error = true
          this.pageStatus.message = response.message
        }
      })
    },
    deleteWarehouse (id) {
      this.confirmDeleteDialog = false
      this.loading = true
      services.deleteWarehouse({
        warehouse_id: id
      }).then(response => {
        if (response.status === 'success') {
          this.alertReporter('Амжилттай', `${id} дугаартай агуулах устлаа`, 'success')
          this.loading = false
          this.$router.push({
            name: 'warehouseList'
          })
        } else if (response.status === 'error') {
          this.alertReporter('Алдаа', `${id} дугаартай агуулахыг устгахад алдаа гарлаа`, 'error')
          this.loading = false
        }
      })
    },
    alertReporter (title, message, type) {
      this.$notify({
        title: title,
        message: message,
        type: type,
        position: 'bottom-left'
      })
    },
    save (formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.loading = true
          if (this.warehouseForm.warehouse_id !== '') {
            services.updateWarehouse(this.warehouseForm).then(data => {
              if (data.status === 'success') {
                this.alertReporter('Амжилттай', data.message, 'success')
                this.loading = false
                this.$router.push({ name: 'warehouseList' })
              } else if (data.status === 'error') {
                this.alertReporter('Алдаа', data.message, 'error')
                this.loading = false
              }
            })
          } else {
            services.addWarehouse(this.warehouseForm).then(data => {
              if (data.status === 'success') {
                this.alertReporter('Амжилттай', data.message, 'success')
                this.loading = false
                this.$router.push({ name: 'warehouseList' })
              } else if (data.status === 'error') {
                this.alertReporter('Алдаа', data.message, 'error')
                this.loading = false
              }
            })
          }
        } else {
          console.log('error submit!!')
          return false
        }
      })
    }
  }
}
</script>
